body {
  .container {
    @media only screen and (min-width: $r-992) {
      max-width: 1920px; } } }

img {
  max-width: 100%; }

// Signup

.signup-section {
  background-color: #f8fafb;
  &--info {
    width: 50%;
    position: relative;
    z-index: 1;
    padding-left: 0;
    padding-right: 0;
    background-color: white;
    @media only screen and (max-width: $r-992) {
      width: 100%; }
    .logo {
      padding: 40px 70px;
      margin-bottom: 30px;
      @media only screen and (max-width: $r-992) {
        padding: 30px 15px; }
      @media only screen and (max-width: $r-768) {
        margin-bottom: 0;
        img {
          height: 35px; } } }
    .image {
      display: flex;
      align-items: center;
      height: 80%;
      max-width: 680px;
      width: 100%;
      margin: 0 auto;
      padding: 0 15px;
      @media only screen and (max-width: $r-992) {
        max-width: 100%;
        height: auto; } } } }

// Signin

.signin-section {
  background-color: #f8fafb;
  &--info {
    width: 50%;
    position: relative;
    z-index: 1;
    padding-left: 0;
    padding-right: 0;
    @media only screen and (max-width: $r-992) {
      width: 100%; }
    &:before {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      height: 45%;
      width: 100%;
      background-color: white;
      z-index: -1;
      @media only screen and (max-width: $r-992) {
        display: none; } }
    .logo {
      padding: 40px 70px;
      margin-bottom: 30px;
      @media only screen and (max-width: $r-992) {
        padding: 30px 15px; }
      @media only screen and (max-width: $r-768) {
        margin-bottom: 0;
        img {
          height: 35px; } } }
    .image {
      max-width: 680px;
      width: 100%;
      margin: 0 auto;
      padding: 0 15px;
      @media only screen and (max-width: $r-992) {
        max-width: 100%; } }
    .item {
      background-color: #f3f6f9;
      padding: 65px 15px;
      @media only screen and (max-width: $r-992) {
        background-color: transparent;
        padding: 30px 15px; }
      .text {
        max-width: 680px;
        width: 100%;
        margin: 0 auto;
        @media only screen and (max-width: $r-992) {
          max-width: 100%; }
        h2 {
          max-width: 250px;
          color: #212529;
          font-size: 19px;
          font-weight: 600;
          margin: 0 0 30px 0; }
        .logos {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          margin: 0 0 15px 0;
          padding: 0;
          li {
            list-style: none;
            margin: 0 45px 30px 0;
            img {
              max-height: 30px; } } }
        h3 {
          color: #212529;
          font-size: 16px;
          font-weight: 600;
          margin: 0 0 10px 0; }
        p {
          color: #949eab;
          font-size: 14px;
          font-weight: 300;
          margin: 0 0 30px 0; }
        .list {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          margin: 0;
          padding: 0;
          li {
            display: flex;
            align-items: center;
            width: 49%;
            list-style: none;
            color: #949eab;
            font-size: 14px;
            font-weight: 300;
            margin-bottom: 10px;
            padding-left: 20px;
            position: relative;
            &:before {
              position: absolute;
              content: '';
              left: 0;
              width: 10px;
              height: 10px;
              background-image: url("../img/icon-list.svg");
              background-size: cover; } } } } } }
  &--form {
    width: 50%;
    padding: 130px 0 65px 0;
    @media only screen and (max-width: $r-992) {
      padding: 30px 0; }
    .form {
      max-width: 460px;
      width: 100%;
      margin: 0 auto;
      @media only screen and (max-width: $r-992) {
        max-width: 100%; }
      h1 {
        color: black;
        font-size: 42px;
        font-weight: 400;
        margin: 0 0 25px 0; }
      p {
        color: black;
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 30px; }
      .google-signup {
        margin-bottom: 30px;
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 65px;
          border-radius: 3px;
          background-color: #3367d6;
          background-image: url("../img/icon-google.svg");
          background-position: 1%;
          background-size: 59px;
          background-repeat: no-repeat;
          color: white;
          font-size: 20px;
          font-weight: 400;
          transition: .3s;
          &:hover {
            transition: .3s;
            text-decoration: none;
            background-color: #0390f9; } } }
      .additional {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-bottom: 30px;
        z-index: 1;
        span {
          color: #b7c8ce;
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 0.08px;
          background-color: #f8fafb;
          padding: 0 10px; }
        &:before {
          position: absolute;
          content: '';
          width: 100%;
          height: 1px;
          background-color: #f2f2f2;
          z-index: -1; } }
      form {
        .item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 40px;
          label {
            display: flex;
            align-items: center;
            color: #212529;
            font-size: 18px;
            font-weight: 400;
            input {
              width: 30px;
              height: 30px;
              margin-right: 15px;
              padding: 0;
              border-radius: 5px;
              &:checked {
                background-color: #0390f9; } } }
          a {
            color: #0097da;
            font-size: 18px;
            font-weight: 400; } }
        input {
          width: 100%;
          height: 84px;
          background-color: white;
          border: 1px solid #f2f2f2;
          color: #b7c8ce;
          font-size: 20px;
          font-weight: 400;
          padding: 0 10px 0 100px;
          background-repeat: no-repeat;
          background-position: 7%;
          background-size: 35px;
          appearance: none;
          outline: none;
          &::placeholder {
            color: #b7c8ce;
            font-size: 20px;
            font-weight: 400; } }
        input[type="email"] {
          border-top-right-radius: 15px;
          border-top-left-radius: 15px;
          background-image: url("../img/icon-email.svg"); }
        input[type="text"] {
          background-image: url("../img/icon-name.svg"); }
        input[type="password"] {
          border-bottom-right-radius: 15px;
          border-bottom-left-radius: 15px;
          margin-bottom: 40px;
          background-image: url("../img/icon-password.svg"); }
        input[type="submit"] {
          padding: 0;
          height: 68px;
          background-color: #0390f9;
          text-transform: uppercase;
          color: white;
          font-size: 20px;
          font-weight: 500;
          transition: .3s;
          &:hover {
            transition: .3s;
            background-color: #3367d6; } } } } } }

